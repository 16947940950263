<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader>
						Image Form
					</CCardHeader>
					<CCardBody>
						<CRow>
							<CCol sm="12">
								<CInput
									v-model="frm.name"
									label="Name"
									placeholder="Enter your name"
								/>
							</CCol>
							<CCol>
								<label for="image">
									Image Active (480 X 640)
									<div class="clearfix p-1 border border-success" id="">
										<img height="110" :src="baseUrlImage(bantuan.image)" />
									</div>
								</label>
								<input
									@change="handleFileUpload"
									class="d-none"
									type="file"
									id="image"
								/>
							</CCol>
						</CRow>
						<CRow>
							<CCol>
								<CButton @click="save" color="success">
									SAVE
								</CButton>
							</CCol>
						</CRow>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import { basuri, debounce, baseUrlImage, getBase64 } from "@/plugins/utils";
export default {
	data() {
		return {
			frm: { name: "", image: "" },
			bantuan: { image: "" },
			baseUrlImage,
			getBase64,
		};
	},
	mounted() {
		if (this.$route.params.id) {
			this.getEdit();
		}
	},
	methods: {
		handleFileUpload(e) {
			let vm = this;
			this.getBase64(e.target.files[0]).then(function(result) {
				//vm.form.mevo_pict_link = result
				vm.bantuan.image = result;
			});
		},
		async getEdit() {
			const id = this.$route.params.id;
			const { data } = await this.$http.get("image/" + id);
			this.frm.name = data.data.name;
			this.bantuan.image = data.data.image;
		},
		async save() {
			try {
				let datar = {};
				this.frm.image = this.bantuan.image;
				if (this.$route.params.id) {
					const { data } = await this.$http.put(
						"image/" + this.$route.params.id,
						this.frm
					);
					datar = data;
				} else {
					const { data } = await this.$http.post("image", this.frm);
					datar = data;
				}

				if (datar.status == "success") {
					this.$toast.success("Success ! " + datar.message);
				} else {
					this.$swal("Fail !", datar.message, "danger");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "danger");
			}
		},
	},
};
</script>
